<template>
  <div class="card-row">
    <USHorizontalScrollbarContainer class="card-row__container">
      <div class="card-row__cards">
        <component
          :is="card.data.to ? 'router-link' : 'div'"
          v-for="(card, idx) in cards"
          :key="idx"
          :to="card.data.to ? card.data.to : undefined"
          class="card-row__card"
        >
          <component :is="getCardComponent(card.type)" v-bind="card.data" />
        </component>
      </div>
    </USHorizontalScrollbarContainer>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, defineProps, PropType } from 'vue'

import CardHole from '@base/components/CardHole/CardHole.vue'
import CardEvent from '@base/components/CardEvent/CardEvent.vue'
import CardListingCourse from '@base/components/CardListingCourse/CardListingCourse.vue'
import CardListingEvent from '@base/components/CardListingEvent/CardListingEvent.vue'
import CardResourceGallery from '@base/components/CardResourceGallery/CardResourceGallery.vue'
import CardResourceText from '@base/components/CardResourceText/CardResourceText.vue'
import CardResourceVideo from '@base/components/CardResourceVideo/CardResourceVideo.vue'
import USHorizontalScrollbarContainer from '@base/components/USHorizontalScrollbarContainer/USHorizontalScrollbarContainer.vue'

import { Card, CardType } from '@base/_types/Card'

defineComponent({
  name: 'CardRow',
  components: {
    CardHole,
    CardEvent,
    CardListingCourse,
    CardListingEvent,
    CardResourceGallery,
    CardResourceText,
    CardResourceVideo,
    USHorizontalScrollbarContainer
  }
})

const props = defineProps({
  cards: {
    type: Array as PropType<Card[]>,
    required: true
  }
})

const getCardComponent = (type: CardType) => {
  switch (type) {
    case CardType.CardEvent:
      return CardEvent
    case CardType.CardHole:
      return CardHole
    case CardType.CardListingCourse:
      return CardListingCourse
    case CardType.CardListingEvent:
      return CardListingEvent
    case CardType.CardResourceGallery:
      return CardResourceGallery
    case CardType.CardResourceText:
      return CardResourceText
    case CardType.CardResourceVideo:
      return CardResourceVideo
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$card-width-mobile: 210px;
$card-width-tablet: 256px;
$card-width-desktop: 370px;

$horizontal-container-margin: calc(
  50% - #{math.div(breakpoint.$breakpoint--max, 2)} + var(--grid--gutter-width)
);

.card-row {
  --card-width: #{$card-width-mobile};

  width: 100%;

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --card-width: #{$card-width-tablet};
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --card-width: #{$card-width-desktop};
  }

  &__cards {
    display: flex;
    flex-flow: row nowrap;
    padding-left: var(--grid--gutter-width);
    padding-right: var(--grid--gutter-width);
    width: fit-content;

    @include breakpoint.min-width(breakpoint.$breakpoint--max) {
      padding-left: $horizontal-container-margin;
      padding-right: $horizontal-container-margin;
    }
  }

  &__card {
    margin-left: var(--spacing--6);
    width: var(--card-width);

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
